export const theme = {
  breakpoints: {
    mobile: '720px',
    small: '480px'
  },
  primary: '#000000',
  colors: {
    background: {
      dark: '#252728'
    },
    dialog: {
      background: '#454545'
    },
    fonts: {
      default: 'white',
      dark: '#252728',
      title: '#4ffbfe',
      lightGreen: '#7fdc43',
      about: {
        title: '#58f1f1'
      }
    }
  },
  font: {
    family: {
      circular: '"CircularStd", sans-serif',
      bebas: 'bebas-neue, sans-serif'
    }
  }
}
