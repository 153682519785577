import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import logo from "assets/images/home/arkeo-logo-white.png"
import ContentBoundaries from "../ContentBoundaries"

const ImageLink = styled(Link)`
  display: inline-block;
`

const FooterContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 60px 80px 60px;

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 50px 0 60px 0;
  }
`

const PrivacyTermsList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: center;

  @media (max-width: 680px) {
    padding: 10px;
  }
`

const TextLinkContainer = styled.li`
  padding: 0 14px;
`

const TextLink = styled(Link)`
  font-family: ${({ theme }) => theme.font.family.bebas};
  color: ${({ theme }) => theme.colors.fonts.default};
  text-decoration: none;
  font-size: 26px;
  white-space: nowrap;

  &:hover {
    color: #7fdc43;
  }
`

const ExternalLink = styled.a`
  font-family: ${({ theme }) => theme.font.family.bebas};
  color: ${({ theme }) => theme.colors.fonts.default};
  text-decoration: none;
  font-size: 26px;
  white-space: nowrap;

  &:hover {
    color: #7fdc43;
  }
`

const listItems = [
  {
    label: `About Us`,
    to: "/about",
  },
  {
    label: "Privacy",
    to: "/privacy",
  },
  {
    label: "Terms",
    to: "/terms",
  },
]

const FlexItem = styled.div`
  flex: 0 1 33%;
  display: flex;
  justify-content: ${({ align }) => align || "center"};
  align-items: center;
`

const FooterWrapper = styled.footer`
  display: flex;
`

const Divider = styled.div`
  border-left: 4px solid white;
  height: 35px;
`

const Footer = () => (
  <FooterWrapper>
    <ContentBoundaries size="large">
      <FooterContentContainer>
        <FlexItem align="flex-start">
          <ImageLink to="/">
            <img src={logo} />
          </ImageLink>
        </FlexItem>

        <PrivacyTermsList>
          <TextLinkContainer>
            <TextLink to={listItems[0].to}>{listItems[0].label}</TextLink>
          </TextLinkContainer>
          <Divider />
          <TextLinkContainer>
            <TextLink to={listItems[1].to}>{listItems[1].label}</TextLink>
          </TextLinkContainer>
          <Divider />
          <TextLinkContainer>
            <TextLink to={listItems[2].to}>{listItems[2].label}</TextLink>
          </TextLinkContainer>
        </PrivacyTermsList>

        <FlexItem align="flex-end">
          <ExternalLink href="https://www.facebook.com/myarkeo/">
            Follow Us +
          </ExternalLink>
        </FlexItem>
      </FooterContentContainer>
    </ContentBoundaries>
  </FooterWrapper>
)

export default Footer
