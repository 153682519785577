import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"

const DialogContainer = styled.div`
  position: relative;
  z-index: 1;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50px;
  bottom: 50px;
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.colors.dialog.background};
  border-radius: 10px;
  border-bottom-left-radius: 0;
`

const Text = styled.p`
  font-family: ${({ theme }) => theme.font.family.circular};
  color: white;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  max-width: 220px;
`

const Button = styled.button`
  background-image: ${({ outline }) => outline ? '' : 'linear-gradient(to right, #49cc5d , #9ae435)'};
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.family.bebas};
  font-size: 22px;
	font-weight: 400;
  color: white;
  border-radius: 40px;
  border: 2px solid #49d16e;
  margin-bottom: 20px;
  padding: 10px 0px;
  max-width: 200px;
  cursor: pointer;
`

const CloseButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  font-family: ${({ theme }) => theme.font.family.circular};
  font-size: 16px;
  color: white;
  cursor: pointer;
`

const PrivacyLink = styled(Link)`
  color: white;
  font-family: ${({ theme }) => theme.font.family.circular};
  color: white;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;

  &:hover {
    color: ${({ theme }) => theme.colors.fonts.lightGreen};
  }
`

const CookiesDialog = ({ onClose }) => {
  const [storage, setStorage] = useState(null)

  useEffect(() => {
    setStorage(localStorage)
  }, [])

  const saveCookiesChoice = () => {
    storage.setItem('isCookiesChoiceSaved', true)
    onClose()
  }

  return (
    <DialogContainer>
      <Wrapper>
        <Text>
          We care about your data and we’d love to
          use cookies to make your experience better.
        </Text>
        <Button onClick={saveCookiesChoice}>
          OK, I AGREE
        </Button>
        <Button
          outline
          onClick={saveCookiesChoice}
        >
          NO THANKS
        </Button>
        <PrivacyLink to="/privacy">See the privacy policy</PrivacyLink>
        <CloseButton onClick={onClose}>X</CloseButton>
      </Wrapper>
    </DialogContainer>
  )
}
  

export default CookiesDialog
