import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #404141;
  opacity: .95;
  z-index: 2;
`
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px;
`

const MenuLink = styled(Link)`
  font-family: ${({ theme }) => theme.font.family.bebas};
  font-size: 250px;
  color: white;
  text-decoration: none;
  line-height: 210px;
  max-width: 300px;

  &:hover {
    color: #7fdc43;
  }

  @media(max-width: 980px) {
		font-size: 200px;
    line-height: 180px;
  }

  @media(max-width: 780px) {
		font-size: 140px;
    line-height: 120px;
  }

  @media(max-width: 580px) {
		font-size: 100px;
    line-height: 90px;
  }

  @media(max-width: 420px) {
		font-size: 70px;
    line-height: 60px;
  }
`
const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 50px;
  cursor: pointer;

  @media(max-width: 450px) {
    right: 20px;
  }
`

const CloseButton = styled.span`
  font-family: ${({ theme }) => theme.font.family.bebas};
  font-size: 100px;
  color: #4ffbfe;

  @media(max-width: 670px) {
		font-size: 60px;
    line-height: 80px;
  }

  @media(max-width: 450px) {
		font-size: 50px;
    line-height: 65px;
  }
`

const CloseText = styled.span`
  font-family: ${({ theme }) => theme.font.family.circular};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #4ffbfe;
  line-height: 0px;

  @media(max-width: 670px) {
		font-size: 15px;
  }
`

const links = [
  {
    label: `Home`,
    to: '/',
  },
  {
    label: 'About',
    to: '/about',
  },
  {
    label: 'Corporate',
    to: '/organisations'
  }
]

const Navigation = ({ onClose }) => (
  <Wrapper>
    <Content>
      {
        links.map(entry => (
          <MenuLink
            to={entry.to}
            key={entry.label}
          >
            {entry.label}
          </MenuLink>
        ))
      }
      <CloseButtonContainer onClick={onClose}>
        <CloseButton>X</CloseButton>
        <CloseText>Close</CloseText>
      </CloseButtonContainer>
    </Content>
  </Wrapper>
)

export default Navigation