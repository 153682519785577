import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import GlobalStyle from 'assets/styles/GlobalStyle'

import { theme } from 'assets/styles/theme'
import SEO from 'components/SEO/SEO'
import CookiesDialog from 'components/CookiesDialog'
import Navigation from 'components/Navigation/Navigation'
import NavigationContext from 'utils/NavigationContext'

const Content = styled.div`
  margin: 0 auto;
  height: 100vh;
`

const MainTemplate = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCookie, setIsCookie] = useState(null)
  const [showMenu, setshowMenu] = useState(false)

  const handleToggleMenu = () => setshowMenu(!showMenu)
  const handleCloseDialog = () => setIsOpen(false)

  useEffect(() => {
    const cookieState = localStorage.getItem('isCookiesChoiceSaved')
    setIsCookie(cookieState)
    setIsOpen(cookieState === null)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <NavigationContext.Provider
        value={{
          showMenu,
          handleToggleMenu
        }}
      >
        <>
          <SEO title={title}/>
          <GlobalStyle />
          <Content style={{ overflowY: showMenu ? 'hidden' : 'auto' }}>
            {
              (isOpen && !isCookie) && <CookiesDialog onClose={handleCloseDialog} />
            }
            {
              showMenu && <Navigation onClose={handleToggleMenu} />
            }
            {children}
          </Content>
        </>
      </NavigationContext.Provider>
    </ThemeProvider>
  )
}

export default MainTemplate
