import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { Link } from "gatsby"

import NavigationContext from 'utils/NavigationContext'
import logo from 'assets/images/home/arkeo-logo-white.png'

const SiteHeader = styled.header`
  position: relative;
  z-index: 1;

  @media(max-width: 855px) {
		background-color: ${({section}) => section === 'about' ? '#171717': 'transparent'}
  }
`

const ImageLink = styled(Link)`
  display: inline-block;

  img {
    @media(max-width: 480px) {
      width: 70%;
    }
  }
`

const HeaderContentContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 480px) {
    padding: 20px 15px;
  }
`

const MenuButton = styled.button`
  border: none;
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.family.circular};
	color: ${({ color }) => color};
	font-size: 30px;
  font-weight: 500;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #7fdc43;
  }

  @media(max-width: 480px) {
    font-size: 24px;
  }
`

const Header = ({ siteTitle, section }) => {

  const { showMenu, handleToggleMenu } = useContext(NavigationContext)

  return (
    <SiteHeader section={section}>
      <HeaderContentContainer>
        <ImageLink to="/">
          <img src={logo} alt="arkeo-logo" />
        </ImageLink>
        <MenuButton
          color={showMenu ? '#7fdc43' : '#ffffff'}
          onClick={handleToggleMenu}
        >
          menu +
        </MenuButton>
      </HeaderContentContainer>
    </SiteHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
